const menuDiv = document.getElementById("injected-code");

function waitForCloseButton() {
  const closeButton = document.getElementById("button-close");
  if (closeButton) {
    closeButton.addEventListener("click", () => {
      menuDiv.innerHTML = "";
    });
  } else {
    setTimeout(waitForCloseButton, 100);
  }
}

document.addEventListener("click", (e) => {
  if (e.target && e.target.id === "button") {
    menuDiv.innerHTML = `<div class="lg:hidden" role="dialog" aria-modal="true"><div class="fixed inset-0  bg-black opacity-30"></div> <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"><div class="flex items-center justify-between"><a href="/" class="flex items-center gap-3"><img src="https://res.cloudinary.com/dq0gbgsdy/image/upload/c_limit,h_120/uploads/6jdcsH7Zf.png" class="w-auto h-[40px]" alt=""></a> <button type="button" id="button-close" class="-m-2.5 rounded-md p-2.5 text-gray-700"><span class="sr-only">Close menu</span> <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></button></div> <div class="mt-6 flow-root"><div class="-my-6 divide-y divide-gray-500/10"><div class="space-y-2 py-6" role="navigation"><a href="#basic-content-image-0" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">About Us</a><a href="#three-column-images-0" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Our Services</a><a href="#basic-contact-form-0" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact Us</a><a class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"></a><!----></div></div></div></div></div>`;
    waitForCloseButton();
  }
});
